import { useNavigate } from "react-router-dom";

function Footer(){
    const navigate = useNavigate();

    const handleClick = (path) => {
        navigate(path);
    };

    return(
        <div className="mt-12">
            <div className="dark:bg-neutral-900 w-full h-14 bottom-0 border-t border-neutral-400 dark:border-neutral-700 flex flex-col lg:flex-row mb-5 p-4 pr-2 z-40 lg:justify-center">
                <a href="https://github.com/Newroll/qr-order" className="font-poppins text-neutral-800 dark:!text-neutral-300 text-lg p-2 lg:mr-12">Github</a>
                <p className="font-poppins text-neutral-800 dark:!text-neutral-300 text-lg p-2 cursor-pointer lg:mr-12"onClick={() => handleClick('/acknowledgements')}>Acknowledgements</p>
                <p className="font-poppins text-neutral-800 dark:!text-neutral-300 text-lg p-2 pb-6 cursor-pointer lg:mr-12"onClick={() => handleClick('/copyright')}>Copyright</p>
            </div>
        </div>
    );
}

export default Footer;
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { ShoppingCartIcon, MoonIcon, SunIcon } from '@heroicons/react/24/solid';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

function Navbar(props) {
    const [showMenu, setShowMenu] = useState(false);
    const [darkMode, setDarkMode] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const isDarkMode = localStorage.getItem('darkMode') === 'true';
        setDarkMode(isDarkMode);
    }, []);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const handleClick = (path) => {
        setShowMenu(false);
        navigate(path);
    };

    const handleCartClick = () => {
        navigate('/cart');
    };

    const toggleDarkMode = () => {
        const newDarkMode = !darkMode;
        setDarkMode(newDarkMode);
        localStorage.setItem('darkMode', newDarkMode);
    };

    useEffect(() => {
        darkMode ? document.body.classList.add('dark') : document.body.classList.remove('dark');
    }, [darkMode]);

    return (
        <div className="dark:bg-neutral-900 bg-neutral-100 w-full h-14 fixed top-0 border-b border-neutral-400 dark:border-neutral-700 flex flex-row p-4 pr-2 items-center z-40">
            <ShoppingCartIcon className="fill-neutral-800 dark:fill-neutral-300 w-6 mr-4 cursor-pointer" onClick={handleCartClick} />
            {darkMode ? (
                <MoonIcon className="fill-neutral-800 dark:fill-neutral-300 w-6 mx-4 cursor-pointer" onClick={toggleDarkMode} />
            ) : (
                <SunIcon className="fill-neutral-800 dark:fill-neutral-300 w-6 mx-4 cursor-pointer" onClick={toggleDarkMode} />
            )}
            {/*<div className="border-neutral-300 border border-l-px h-10"/>*/}
            <p className="font-poppins space-y-4 m-4 mr-6 ml-auto text-neutral-800 dark:text-neutral-300 text-right text-lg cursor-pointer hidden sm:block" onClick={() => handleClick('/entrees')}>Entrees</p>
            <p className="font-poppins space-y-4 m-4 mr-6 text-neutral-800 dark:text-neutral-300 text-right text-lg cursor-pointer hidden sm:block" onClick={() => handleClick('/mains')}>Mains</p>
            <p className="font-poppins space-y-4 m-4 mr-6 text-neutral-800 dark:text-neutral-300 text-right text-lg cursor-pointer hidden sm:block" onClick={() => handleClick('/desserts')}>Desserts</p>
            <p className="font-poppins space-y-4 m-4 mr-6 text-neutral-800 dark:text-neutral-300 text-right text-lg cursor-pointer hidden sm:block" onClick={() => handleClick('/beverages')}>Beverages</p>
            <p className="font-poppins text-neutral-800 dark:!text-neutral-300 text-xl ml-auto cursor-pointer sm:hidden"
                onClick={(e) => {
                    e.stopPropagation();
                    toggleMenu();
                }}>{props.page}</p>
            {showMenu ? (
                <ChevronUpIcon
                    className="w-8 fill-neutral-800 dark:fill-neutral-300 ml-1 cursor-pointer sm:hidden"
                    onClick={toggleMenu}
                />
            ) : (
                <ChevronDownIcon
                    className="w-8 fill-neutral-800 dark:fill-neutral-300 ml-1 cursor-pointer sm:hidden"
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleMenu();
                    }}
                />
            )}
            {showMenu && (
                <>
                    <div className="bg-neutral-300 dark:bg-neutral-800 w-40 absolute top-10 right-2 z-50 rounded-3xl border-neutral-400 dark:border-neutral-700 border">
                        <p className="font-poppins space-y-4 m-4 mr-6 text-neutral-800 dark:text-neutral-300 text-right text-lg cursor-pointer" onClick={() => handleClick('/entrees')}>Entrees</p>
                        <p className="font-poppins space-y-4 m-4 mr-6 text-neutral-800 dark:text-neutral-300 text-right text-lg cursor-pointer" onClick={() => handleClick('/mains')}>Mains</p>
                        <p className="font-poppins space-y-4 m-4 mr-6 text-neutral-800 dark:text-neutral-300 text-right text-lg cursor-pointer" onClick={() => handleClick('/desserts')}>Desserts</p>
                        <p className="font-poppins space-y-4 m-4 mr-6 text-neutral-800 dark:text-neutral-300 text-right text-lg cursor-pointer" onClick={() => handleClick('/beverages')}>Beverages</p>
                    </div>
                    <div className="fixed top-14 left-0 w-full h-full bg-black opacity-70" onClick={toggleMenu}></div>
                </>
            )}
        </div>
    );
}

export default Navbar;
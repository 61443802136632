import { useState } from "react";
import ItemCardLModified from "./item-card-l-modified";

function ItemCardM(props) {
    const [showModal, setShowModal] = useState(false);

    function handleOpenModal() {
      setShowModal(true);
    }
  
    function handleCloseModal() {
      setShowModal(false);
      props.updateDOM();
    }

    const itemObject = props.cartObject[props.index];

    var price = itemObject.item.price * itemObject.quantity;

    for (let i = 0; i < Object.keys(itemObject.option).length; i++) {
        if (itemObject.option[i] != null){
            let modifier = itemObject.item.options[itemObject.option[i]+1] * itemObject.quantity;
            price += modifier;
        }
    }

    return (
        <>
            <div className="w-11/12 h-32 bg-neutral-300 dark:bg-neutral-800 border-neutral-400 dark:border-neutral-700 rounded-2xl flex flex-row border relative ">
                <img src={require(`../img/${itemObject.item?.id}.webp`)} alt="Bing Chilling" className="w-18 h-18 rounded-3xl p-4" />
                <div className="flex flex-col">
                    <p className="font-poppings text-neutral-950 dark:text-neutral-100 text-2xl pt-3">{itemObject.item?.name}</p>
                    <p className="font-poppings text-neutral-700 dark:text-neutral-300 cursor-pointer" onClick={handleOpenModal}>Tap to edit</p>
                    <div className="flex flex-row pb-4 mt-auto">
                        <p className="font-poppings text-neutral-950 dark:text-neutral-100 text-2xl flex flex-col">${price.toFixed(2)}</p>
                    </div>
                </div>
            </div>
            {showModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center">
                    <div className="absolute inset-0 bg-black opacity-50" onClick={handleCloseModal}></div>
                    <ItemCardLModified handleCloseModal={handleCloseModal} item={itemObject.item} itemObject={itemObject} index={props.index} price={price}/>
                </div>
            )}
        </>
    );
}

export default ItemCardM;
function ItemCardMModified(props) {

    const itemObject = props.cartObject[props.index];

    return (
        <>
            <div className="w-11/12 h-32 bg-neutral-300 dark:bg-neutral-800 border-neutral-400 dark:border-neutral-700 rounded-2xl flex flex-row border relative ">
                <img src={require(`../img/${itemObject.item?.id}.webp`)} alt="Bing Chilling" className="w-18 h-18 rounded-3xl p-4" />
                <div className="flex flex-col">
                    <p className="font-poppings text-neutral-950 dark:text-neutral-100 text-2xl pt-3">{itemObject.item?.name}</p>
                    <div className="flex flex-row pb-4 mt-auto">
                        <p className="font-poppings text-neutral-950 dark:text-neutral-100 text-2xl flex flex-col">${(itemObject.item?.price * itemObject.quantity).toFixed(2)}</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ItemCardMModified;
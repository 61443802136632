import { useNavigate } from "react-router-dom";
import ItemCardMModified from '../components/item-card-m-modified';
import { useEffect } from "react";
import Footer from "../components/footer";

function Cart() {
    const cartObj = JSON.parse(localStorage.getItem('cart'));
    const table = localStorage.getItem('table');
    const navigate = useNavigate();

    /*function cancelOrder() {
        alert("DEBUG-order-cancel");
    }*/

    function newOrder() {
        navigate('/');
    }

    let cartLength = 0;

    if (cartObj != null) {
        cartLength = Object.keys(cartObj).length;
    }

    let price = 0;

    for (let i = 0; i < cartLength; i++) {
        price += cartObj[i].item.price * cartObj[i].quantity;
    };

    useEffect(() => {
        localStorage.clear();
    }, []);


    return (
        <div className="flex flex-col relative">
            <div className="bg-neutral-100 dark:bg-neutral-900 w-full h-14 fixed top-0 border-b border-neutral-700 flex items-center z-50">
                <p className="font-poppins text-neutral-800 dark:text-neutral-300 relative text-center items-center justify-center text-lg p-4 cursor-pointer" onClick={newOrder}>New Order</p>
            </div>
            <div className="lg:w-1/3 m-auto">
                <p className="font-poppins text-neutral-800 dark:text-neutral-300 text-4xl mt-16 p-4 pb-2">Order Confirmed!</p>
                <p className="font-poppins text-neutral-700 dark:text-neutral-400 text-lg p-4 pt-0">You will receive your items shortly.</p>
                <div className="grid grid-cols-1 justify-items-center gap-4 mb-4">
                    {Array.from({ length: cartLength }).map((_, index) => (
                        <ItemCardMModified index={index} cartObject={cartObj} />
                    ))}
                </div>
                <div className="flex flex-row">
                    <p className="font-poppins text-neutral-800 dark:text-neutral-300 text-2xl p-4 pt-0">Table</p>
                    <p className="font-poppins text-neutral-800 dark:text-neutral-300 text-2xl p-4 pt-0 ml-auto">{table}</p>
                </div>
                <div className="flex flex-row">
                    <p className="font-poppins text-neutral-800 dark:text-neutral-300 text-2xl p-4 pt-0">Total</p>
                    <p className="font-poppins text-neutral-800 dark:text-neutral-300 text-2xl p-4 pt-0 ml-auto">${price.toFixed(2)}</p>
                </div>
                {/*<div className="w-36 h-10 bg-red-500 rounded-2xl ml-4 flex items-center justify-center cursor-pointer" onClick={cancelOrder}>
                    <p className="font-poppins text-neutral-700 dark:text-neutral-300 text-m text-center">CANCEL ORDER</p>
                    </div>*/}
            </div>
            <Footer />
        </div>
    );
}

export default Cart;
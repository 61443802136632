import { PlusIcon } from '@heroicons/react/20/solid';
import { useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';

export function Options(props) {
    const [itemAdded, setItemAdded] = useState(false);

    useEffect(() => {
        if (props.optionsArr[props.index] == null) {
            setItemAdded(false);
        } else {
            setItemAdded(true);
        }
    }, [props.optionsArr, props.index]);

    function handleOptionsChange() {
        if (itemAdded) {
            setItemAdded(false);
            props.handleOptionsChange(null, props.index);
        } else {
            setItemAdded(true);
            props.handleOptionsChange(props.index * 2, props.index);
        }
    }

    return (
        <div className="justify-center flex p-2">
            <div className="w-11/12 h-10 bg-neutral-100 dark:bg-neutral-300 rounded-2xl flex flex-row items-center border-neutral-800 border">
                <p className="text-neutral-800 font-poppins text-lg py-2 pl-2">{props.item.options[props.index * 2]} · ${props.item.options[(props.index * 2) + 1].toFixed(2)}</p>
                <div className="w-12 h-10 ml-auto flex flex-col rounded-r-2xl items-center justify-center" onClick={handleOptionsChange}>
                    {itemAdded ? (
                        <XMarkIcon
                            className="fill-neutral-800 relative px-3"
                            onClick={handleOptionsChange}
                        />
                    ) : (
                        <PlusIcon
                            className="fill-neutral-800 relative px-3"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleOptionsChange();
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
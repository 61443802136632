import ItemCardS from "../components/item-card-s"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import {} from "../firebase"
import { ref, getDatabase, child, get } from "firebase/database"
import { useState, useEffect } from "react"

function PageEntree() {
    const [db, setdb] = useState(null);

    useEffect(() => {
        const dbRef = ref(getDatabase());
        get(child(dbRef, '/entree')).then((snapshot) => {
            const dbObject = JSON.parse(JSON.stringify(snapshot.val()));
            setdb(dbObject);
        });
    }, []);

    const amount = db ? Object.keys(db).length : 0;
    
    return (
        <div className="flex flex-col relative">
            <Navbar page = {"Entrees"}/>
            <div className="grid gap-4 z-10 justify-items-center items-center px-4 overflow-hidden mt-20 sm:grid-cols-4 lg:grid-cols-5 grid-cols-2" draggable="false">
                {Array.from({ length: amount }).map((_, index) => (
                    <ItemCardS key = {index} item = {db ? Object.values(db)[index] : null}/>
                ))}
            </div>
            <Footer/>
        </div>
    );
}

export default PageEntree;
import React, { useState } from 'react';
import ItemCardL from './item-card-l';

function ItemCardS(props) {
  const [showModal, setShowModal] = useState(false);

  function handleOpenModal() {
    setShowModal(true);
    document.body.classList.add('overflow-hidden');
  }

  function handleCloseModal() {
    setShowModal(false);
    document.body.classList.remove('overflow-hidden');
  }

  const name = props.item?.name;
  const price = props.item?.price;
  const image = props.item?.id;

  return (
    <>
      <div className="relative rounded-xl h-full bg-neutral-200 dark:bg-neutral-800 border-neutral-400 dark:border-neutral-700 border">
      <img src={require(`../img/${image}.webp`)} alt={name} className="p-4 pb-1 relative rounded-3xl" />
        <p className="text-neutral-950 dark:text-neutral-100 p-4 pt-0 pb-0 relative text-xl font-poppins">{name}</p>
        <p className="text-neutral-700 dark:text-neutral-300 p-4 pt-0 relative text-lg font-poppins">${price.toFixed(2)}</p>
        <button onClick={handleOpenModal} className="absolute inset-0 w-full h-full bg-black opacity-0 lg:hover:opacity-50 transition-opacity duration-300 rounded-xl"></button>
      </div>
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="absolute inset-0 bg-black opacity-70" onClick={handleCloseModal}></div>
            <ItemCardL handleCloseModal={handleCloseModal} item={props.item}/>
          </div>
      )}
    </>
  );
}

export default ItemCardS;
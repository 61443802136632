import { XMarkIcon } from '@heroicons/react/20/solid';
import { Options } from './options';

function ItemCardL(props) {
    let qty = 1;
    let optionsArr = [];
    let choiceStr;
    let commentStr;
    const cartObject = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : {};

    const handleOptionsChange = (changingOption, index) => {
        optionsArr[index]=changingOption;
    }

    function qtyChange() {
        const qtyInput = document.getElementById("itemqty").value;
        qty = parseInt(qtyInput);
    }

    function choiceChange() {
        const choiceInput = document.getElementById("choice").value;
        choiceStr = parseInt(choiceInput);

    }

    function commentChange() {
        commentStr = document.getElementById("comment").value
    }

    function addToCart() {

        const item = {
            item: props.item,
            quantity: qty,
            option: optionsArr,
            choice: choiceStr,
            comment: commentStr
        };

        cartObject[Object.keys(cartObject).length] = item;
        localStorage.setItem('cart', JSON.stringify(cartObject));
        props.handleCloseModal();
    }

    const name = props.item?.name;
    const price = props.item?.price;
    const image = props.item?.id;
    const description = props.item?.description;
    let optionsLength = 0;

    if (props.item?.options != null) {
        optionsLength = props.item.options.length / 2;
    }

    return (
        <div className="relative flex justify-center items-center lg:w-1/4 md:w-1/3 w-5/6" draggable="false">
            <div className="bg-neutral-300 dark:bg-neutral-800 w-5/6 h-fit rounded-3xl border-neutral-700 border relative" draggable="false">
                <XMarkIcon className="absolute top-2 right-2 w-8 h-8 text-neutral-400 cursor-pointer" onClick={props.handleCloseModal} />
                <img src={require(`../img/${image}.webp`)} alt={image} className="w-full h-32 object-cover rounded-3xl rounded-b-none" />
                <p className="text-neutral-950 dark:text-neutral-100 p-4 pb-2 font-poppins text-2xl">{name} · ${price.toFixed(2)}</p>
                <div className="flex flex-row">
                    <p className="px-4 pt-0 text-neutral-700 dark:text-neutral-300 font-poppins">{description}</p>
                    <div className="p-4 pt-0 ml-auto flex flex-col">
                        <select id="itemqty" name="Item Quantity" className="font-poppins rounded-lg p-2 text-xl pr-6 text-neutral-800 bg-neutral-100 dark:bg-neutral-300 border-neutral-800 border cursor-pointer" onChange={qtyChange}>
                            <option value="1" selected>1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                        </select>
                    </div>
                </div>
                {Array.from({ length: optionsLength }).map((_, index) => (
                    <Options index={index} item={props.item} handleOptionsChange={handleOptionsChange} />
                ))}
                {props.item.choices != null ? (
                    <div className="justify-center flex p-2">
                        <select id="choice" name="Choices" className="font-poppins rounded-2xl text-lg bg-neutral-100 dark:bg-neutral-300 text-neutral-800 w-11/12 h-10 px-2 border-neutral-800 border" onChange={choiceChange}>
                            <option value="nochoice" selected disabled>{props.item.choices[0]}</option>
                            {Array.from({ length: Object.keys(props.item.choices).length - 1 }).map((_, index) => (
                                <option value={index}>{props.item.choices[index + 1]}</option>
                            ))}
                        </select>
                    </div>
                ) : null}
                <div className="justify-center flex p-2">
                    <input type="text" id="comment" name="comment" placeholder="Enter comment..." className="font-poppins w-11/12 h-10 rounded-2xl pl-2 text-lg bg-neutral-100 dark:bg-neutral-300 placeholder-neutral-500 border-neutral-800 border" onBlur={commentChange}>
                    </input>
                </div>
                <hr className="h-px border-0 bg-neutral-700 mx-3" />
                <div className="justify-center flex p-4 pt-4">
                    <button className="font-poppins w-11/12 h-14 rounded-2xl text-2xl bg-neutral-100 dark:bg-neutral-300 border border-neutral-800" onClick={addToCart}>Add to cart</button>
                </div>
            </div>
        </div>
    );
}

export default ItemCardL;
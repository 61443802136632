import { useNavigate } from "react-router-dom";

function Acknowledgements() {

    const navigate = useNavigate();

    function handleBackClick() {
        navigate(-1);
    }

    return (
        <div className="flex flex-col relative">
            <div className="bg-neutral-100 dark:bg-neutral-900 w-full h-14 fixed top-0 border-b border-neutral-700 flex items-center z-50">
                <p className="font-poppins text-neutral-800 dark:text-neutral-300 relative text-center items-center justify-center text-lg p-4" onClick={handleBackClick}>Back</p>
            </div>
            <p className="font-poppins text-neutral-800 dark:text-neutral-300 text-3xl mt-16 p-4 pb-2">Acknowledgements</p>
            <p className="font-poppins text-neutral-800 dark:text-neutral-300 text-xl p-4 pb-2">Food.pics image dataset</p>
            <p className="font-poppins text-neutral-700 dark:text-neutral-400 text-m p-4 pt-0">Blechert J, Lender A, Polk S, Busch NA and Ohla K (2019). Food-Pics_Extended—An Image Database for Experimental Research on Eating and Appetite: Additional Images, Normative Ratings and an Updated Review. Front. Psychol. 10:307. doi:10.3389/fpsyg.2019.00307</p>
            <p className="font-poppins text-neutral-700 dark:text-neutral-400 text-m p-4 pt-0">Entrees: #708, scallops in sauce #10, spaghetti w/ tomato sauce #700, salad #229, salad #307, salmon #481, flat cake #564, sushi #567, tomato and mozzarella #599, chicken soup #677, mushroom soup #693, pumpkin soup. Mains: #408, grilled whole fish #484, ribeye #493, ribs #556, viennese schnitzel #30, whole chicken #57, ham sandwich #694, pizza #690, spaghetti #685, pizza #591, chicken pot pie. Desserts: #320, creme brulee #325, fresh fruits #354, tartlet #421, raspberry cream cake #400, eclair #491, sundae #675, chocolate cake #6, cheesecake #28, ice cream cake #89, strawberry cake. Beverages: #388, white wine #390, red wine #358, juice #456, beer #573, milk #472, coffee</p>
            <p className="font-poppins text-neutral-800 dark:text-neutral-300 text-xl p-4 pb-2">Github Copilot</p>
            <a href="https://copilot.github.com" className="font-poppins text-neutral-700 dark:text-neutral-400 text-m p-4 pt-0">https://copilot.github.com/</a>
            <p className="font-poppins text-neutral-800 dark:text-neutral-300 text-xl p-4 pb-2">React</p>
            <a href="https://react.dev" className="font-poppins text-neutral-700 dark:text-neutral-400 text-m p-4 pt-0">https://react.dev/</a>
            <p className="font-poppins text-neutral-800 dark:text-neutral-300 text-xl p-4 pb-2">Tailwind CSS</p>
            <a href="https://tailwindcss.com" className="font-poppins text-neutral-700 dark:text-neutral-400 text-m p-4 pt-0">https://tailwindcss.com/</a>
            <p className="font-poppins text-neutral-800 dark:text-neutral-300 text-xl p-4 pb-2">Heroicons</p>
            <a href="https://heroicons.com" className="font-poppins text-neutral-700 dark:text-neutral-400 text-m p-4 pt-0">https://heroicons.com/</a>
            <p className="font-poppins text-neutral-800 dark:text-neutral-300 text-xl p-4 pb-2">React Router</p>
            <a href="https://reactrouter.com" className="font-poppins text-neutral-700 dark:text-neutral-400 text-m p-4 pt-0">https://reactrouter.com/</a>
            
        </div>
    );
}

export default Acknowledgements;
import { Routes, Route } from 'react-router-dom';
import Cart from './pages/cart';
import Confirm from './pages/confirm';
import Entrees from './pages/page-entree';
import Mains from './pages/page-main';
import Desserts from './pages/page-dessert';
import Beverages from './pages/page-beverage';
import Acknowledgements from './pages/acknowledgements';
import Copyright from './pages/copyright';

const Routing = () => {
 return (
    <>
       <Routes>
          <Route path="/" element={<Entrees />} />
          <Route path="cart" element={<Cart />} />
          <Route path="confirmed" element={<Confirm />} />
          <Route path="entrees" element={<Entrees />} />
          <Route path="mains" element={<Mains />} />
          <Route path="desserts" element={<Desserts />} />
         <Route path="beverages" element={<Beverages />} />
         <Route path="acknowledgements" element={<Acknowledgements />} />
         <Route path="copyright" element={<Copyright />} />
       </Routes>
    </>
 );
};

export default Routing;
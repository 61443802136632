import { useNavigate } from "react-router-dom";
import ItemCardM from '../components/item-card-m';
import { useState } from "react";

function Cart() {
    const [update, setUpdate] = useState(false);
    const navigate = useNavigate();

    const cartObject = JSON.parse(localStorage.getItem('cart'));

    function handleBackClick() {
        navigate(-1);
    }

    function confirmOrder() {
        navigate('/confirmed');
    }

    function setTable() {
        localStorage.setItem("table", document.getElementById("tableno").value)
    }

    let cartLength = 0;

    if (cartObject != null) {
        cartLength = Object.keys(cartObject).length;
    }

    let price = 0;

    for (let i = 0; i < cartLength; i++) {
        price += cartObject[i].item.price * cartObject[i].quantity;
        let itemObject = cartObject[i];
        for (let j = 0; j < Object.keys(itemObject.option).length; j++) {
            if (itemObject.option[j] != null){
                let modifier = itemObject.item.options[itemObject.option[j]+1] * itemObject.quantity;
                price += modifier;
            }
        }
    }; 

    function handleUpdate() {
        setUpdate(!update);
    }

    return (
        <div className="flex flex-col relative">
            <div className="bg-neutral-100 dark:bg-neutral-900 w-full h-14 fixed top-0 border-b border-neutral-700 flex items-center z-50">
                <p className="font-poppins text-neutral-800 dark:text-neutral-300 relative text-center items-center justify-center text-lg p-4 lg:absolute cursor-pointer" onClick={handleBackClick}>Back</p>
                <p className="font-poppins text-neutral-800 dark:text-neutral-300 text-2xl p-4 ml-auto lg:mr-auto">Order Overview</p>
            </div>
            <div className="lg:w-1/3 lg:m-auto">
                <div className="mt-20 grid grid-cols-1 justify-items-center gap-4 mb-8">
                    {Array.from({ length: cartLength }).map((_, index) => (
                        <ItemCardM index={index} cartObject={cartObject} updateDOM={handleUpdate} />
                    ))}
                </div>
                <div className="flex flex-row">
                    <p className="font-poppins text-neutral-800 dark:text-neutral-300 text-2xl p-4 items-center">Table</p>
                    <div className="p-4 ml-auto">
                        <select id="tableno" name="Table Number" className="font-poppins rounded-lg text-xl pr-6 pl-4 py-1 text-neutral-800 bg-neutral-100 dark:bg-neutral-300 border-neutral-800 border cursor-pointer" onChange={setTable}>
                            <option value="1" selected>1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                        </select>
                    </div>
                </div>
                <div className="flex flex-row">
                    <p className="font-poppins text-neutral-800 dark:text-neutral-300 text-2xl p-4 pt-0">Total</p>
                    <p className="font-poppins text-neutral-800 dark:text-neutral-300 text-2xl p-4 pt-0 ml-auto">${price.toFixed(2)}</p>
                </div>
                <hr className="h-px border-0 bg-neutral-700 mx-4" />
                <div className="bg-neutral-100 dark:bg-neutral-300 border border-neutral-800 h-16 w-5/6 rounded-xl items-center justify-center m-auto mt-4 mb-12 cursor-pointer" onClick={confirmOrder}>
                    <p className="font-poppins text-neutral-900 text-3xl p-3 m-auto text-center cursor-pointer">Confirm Order</p>
                </div>
            </div>
        </div>
    );
}

export default Cart;
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Options } from './options-modified';
import { useState } from 'react';

function ItemCardL(props) {
    let optionsArr = props.itemObject.option;
    const cartObject = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : {};

    const [itemObject, setItemObject] = useState(props.itemObject);

    const handleQuantityChange = (event) => {
        const value = event.target.value;
        setItemObject({ ...itemObject, quantity: value });
    };

    const handleChoiceChange = (event) => {
        const value = event.target.value;
        setItemObject({ ...itemObject, choice: value });
    };

    const handleCommentChange = (event) => {
        const value = event.target.value;
        setItemObject({ ...itemObject, comment: value });
    };

    const handleOptionsChange = (changingOption, index) => {
        optionsArr[index]=changingOption;
    }

    const item = props.item;
    const indexNumber = props.index;
    const handleCloseModal = props.handleCloseModal;

    function confirmEdit() {
        let tempQuantity = null
        let tempChoice = null
        let tempComment = null

        if (itemObject.quantity != null) {
            tempQuantity = parseInt(itemObject.quantity);
        }

        if (itemObject.choice != null) {
            tempChoice = parseInt(itemObject.choice);
        }

        if (itemObject.comment != null) {
            tempComment = itemObject.comment;
        }

        const item = {
            item: props.item,
            quantity: tempQuantity,
            option: optionsArr,
            choice: tempChoice,
            comment: tempComment
        };

        cartObject[indexNumber] = item;
        localStorage.setItem('cart', JSON.stringify(cartObject));
        handleCloseModal();
    }

    function deleteItem(){
        delete cartObject[indexNumber];
        localStorage.setItem('cart', JSON.stringify(cartObject));
        handleCloseModal();
    }

    const name = item?.name;
    const image = item?.id;
    const description = item?.description;
    let optionsLength = 0;

    if (item?.options != null) {
        optionsLength = item.options.length / 2;
    }

    return (
        <div className="relative flex justify-center items-center" draggable="false">
            <div className="bg-neutral-300 dark:bg-neutral-800 w-5/6 h-fit rounded-3xl border-neutral-700 border relative" draggable="false">
                <XMarkIcon className="absolute top-2 right-2 w-8 h-8 text-neutral-400" onClick={props.handleCloseModal} />
                <img src={require(`../img/${image}.webp`)} alt={image} className="w-full h-32 object-cover rounded-3xl rounded-b-none" />
                <p className="text-neutral-950 dark:text-neutral-100 p-4 pb-2 font-poppins text-2xl">{name} · ${props.price.toFixed(2)}</p>
                <div className="flex flex-row">
                    <p className="px-4 pt-0 text-neutral-700 dark:text-neutral-300 font-poppins">{description}</p>
                    <div className="p-4 pt-0 ml-auto flex flex-col">
                        <select id="itemqty" name="Item Quantity" className="font-poppins rounded-lg p-2 text-xl pr-6 text-neutral-800 bg-neutral-100 dark:bg-neutral-300 border-neutral-800 border" value={itemObject.quantity} onChange={handleQuantityChange}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                        </select>
                    </div>
                </div>
                {Array.from({ length: optionsLength }).map((_, index) => (
                    <Options index={index} item={item} optionsArr = {optionsArr} handleOptionsChange={handleOptionsChange} />
                ))}
                {props.item.choices != null ? (
                    <div className="justify-center flex p-2">
                        <select id="choice" name="Choices" className="font-poppins rounded-2xl text-lg bg-neutral-100 dark:bg-neutral-300 text-neutral-800 w-11/12 h-10 px-2 border-neutral-800 border" value={itemObject.choice} onChange={handleChoiceChange}>
                        <option value="nochoice" selected disabled>{item.choices[0]}</option>
                            {Array.from({ length: Object.keys(item.choices).length - 1 }).map((_, index) => (
                                <option value={index}>{item.choices[index + 1]}</option>
                            ))}
                        </select>
                    </div>
                ) : null}
                <div className="justify-center flex p-2">
                    <input type="text" id="comment" name="comment" placeholder="Enter comment..." className="font-poppins w-11/12 h-10 rounded-2xl pl-2 text-lg bg-neutral-100 dark:bg-neutral-300 placeholder-neutral-500 border-neutral-800 border" value={itemObject.comment} onChange={handleCommentChange}>
                    </input>
                </div>
                <hr className="h-px border-0 bg-neutral-700 mx-3" />
                <div className="justify-center flex p-4 pt-4">
                <button className="font-poppins w-11/12 h-14 rounded-2xl text-xl bg-red-500 dark:bg-red-500 border border-neutral-800 mr-4" onClick={deleteItem}>Delete item</button>
                    <button className="font-poppins w-11/12 h-14 rounded-2xl text-xl bg-neutral-100 dark:bg-neutral-300 border border-neutral-800" onClick={confirmEdit}>Confirm edit</button>
                </div>
            </div>
        </div>
    );
}

export default ItemCardL;